import { saasModernTheme } from 'common/theme/saasModern';
import styled from 'styled-components';

export const SectionFaq = styled.section`
  padding: ${saasModernTheme.space[2]}px;
`;

export const FaqWrapper = styled.div`
  .accordion_item {
    border-radius: 5px;
    margin-bottom: 5px;
  }

  .accordion_title {
    padding: 23px 0;
    border-bottom: 1px solid ${saasModernTheme.colors.white};
    @media (max-width: 575px) {
      padding: 15px 0;
    }
  }

  .accordion_body {
    padding: 0 30px 23px 30px;
    border-bottom: 2px solid ${saasModernTheme.colors.white};
    @media (max-width: 575px) {
      padding: 0 20px 15px 20px;
    }
  }
`;

export const FaqQuestion = styled.h3`
  font-family: Raleway, Helvetica;
  color: ${saasModernTheme.colors.white};
  margin: 0;
  font-size: ${saasModernTheme.space[5]}px;
  font-weight: bold;
  @media (max-width: 575px) {
    font-size: ${saasModernTheme.space[4]}px;
  }
`;

export const FaqAnswer = styled.div`
  font-family: Raleway, Helvetica;
  color: ${saasModernTheme.colors.white};
`;

export const ParentFaq = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${saasModernTheme.space[10]}px;
  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

export const ChildFaq = styled.div`
  flex: 1;
`;

export const HeaderFaq = styled.header``;

export const MarginDescription = styled.div`
  margin-bottom: ${saasModernTheme.space[8]}px;
`;

export const FaqAside = styled.aside`
  width: 100%; // Ajustez ce pourcentage selon vos besoins
  background: rgba(255, 255, 255, 0.05);
  z-index: 0;
  padding-bottom: 50%; // Ceci maintient le rapport d'aspect 1:1 pour le carré
  position: relative;
  border-radius: ${saasModernTheme.radius[4]}px;
  overflow: hidden;
  margin-top: ${saasModernTheme.space[8]}px;
  @media (max-width: 950px) {
    margin-top: ${saasModernTheme.space[7]}px;
  }
`;
